/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-02",
    versionChannel: "nightly",
    buildDate: "2023-10-02T00:25:18.095Z",
    commitHash: "58b18e282b64629139ce64b4d6202f214bb8c72a",
};
